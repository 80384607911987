import React from 'react';

import { css, sva } from '@headout/pixie/css';

import Image from 'Components/common/image';
import LH2 from 'Components/common/localizedTags/localizedHeading2';
import LSpan from 'Components/common/localizedTags/localizedSpan';

import {
	getAppVendorIcons,
	IPHONE_MOCKUP_IMAGE_URLS,
} from 'Constants/constants';
import { strings } from 'Constants/strings';

const downloadAppSectionRecipe = sva({
	slots: ['container', 'phoneWrapper', 'appDetails'],
	base: {
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			minWidth: '70rem',
			maxWidth: '75rem',
			margin: '0 auto',
			overflow: 'hidden',
		},
		phoneWrapper: {
			'& img': {
				objectFit: 'cover',
			},
		},
		appDetails: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
		},
	},
	variants: {
		isMid: {
			true: {
				container: {
					height: '21.625rem',
					background: 'core.purps.10',
					borderRadius: '0.75rem',
				},
				phoneWrapper: {
					'& img': {
						background: 'core.purps.10',
					},
				},
				appDetails: {
					marginTop: '0',
					padding: '4rem 4.875rem 5.25rem',
				},
			},
			false: {
				container: {
					height: '24.125rem',
					background: 'semantic.surface.light.white',
					borderRadius: 0,
				},
				phoneWrapper: {
					'& img': {
						background: 'semantic.surface.light.white',
					},
				},
				appDetails: {
					marginTop: '0.875rem',
					padding: 0,
				},
			},
		},
	},
});

const sectionTitle = css({
	color: 'semantic.text.grey.2',
	cursor: 'default',
	marginTop: '0.9375rem',
	textStyle: 'Semantics/Display/Small',
});

const sectionSubtitle = css({
	marginTop: '1rem',
	color: 'semantic.text.grey.2',
	width: '27rem',
	cursor: 'default',
	textStyle: 'Semantics/Para/Large',
});

const downloadLinks = css({
	display: 'flex',
	marginTop: '3rem',

	'& a img': {
		height: '2.5rem',
	},
});

const playStore = css({
	marginLeft: '1rem',
});

type Props = {
	isMid?: boolean;
	languageCode?: string;
};

const DownloadAppSection = ({ isMid, languageCode = 'en' }: Props) => {
	const langSlug = languageCode !== 'en' ? '/' + languageCode : '';
	const { container, phoneWrapper, appDetails } = downloadAppSectionRecipe({
		isMid,
	});

	const { PLAY_STORE, APP_STORE } = getAppVendorIcons(languageCode);
	return (
		<div className={container} data-qa-marker={'download-app-section'}>
			<div className={appDetails}>
				<LH2 className={sectionTitle}>
					{strings.DOWNLOAD_APPS.TITLE}
				</LH2>
				<LSpan className={sectionSubtitle}>
					{strings.DOWNLOAD_APPS.SUB_HEADING}
				</LSpan>
				<div className={downloadLinks}>
					<a
						rel='noopener noreferrer'
						target='_blank'
						href={`https://apps.apple.com${langSlug}/app/headout-travel-experiences/id899327000?ls=1&mt=8`}
						onMouseDown={e => {
							e.stopPropagation();
						}}
						data-qa-marker='download-app-ios'
					>
						<Image
							alt='Download iPhone app'
							src={APP_STORE}
							height={40}
							width={120}
						/>
					</a>
					<a
						rel='noopener noreferrer'
						target='_blank'
						href={`https://play.google.com/store/apps/details?id=com.tourlandish.chronos&hl=${languageCode}`}
						className={playStore}
						onMouseDown={e => {
							e.stopPropagation();
						}}
						data-qa-marker='download-app-android'
					>
						<Image
							alt='Download Android app'
							src={PLAY_STORE}
							width={136}
							height={40}
						/>
					</a>
				</div>
			</div>
			<div className={phoneWrapper}>
				<div>
					<Image
						alt='iphone'
						className='image'
						src={IPHONE_MOCKUP_IMAGE_URLS[languageCode]}
						height={400}
						width={640}
					/>
				</div>
			</div>
		</div>
	);
};

export default DownloadAppSection;
